<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase">Payment Channel</h1>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-if="form"
          v-bind:class="$isLoading ? 'd-none' : 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4">
            <b-row>
              <b-col md="6">
                <div
                  class="preview-box b-contain"
                  v-bind:style="{
                    'background-image': 'url(' + form.coverImage + ')',
                  }"
                  :class="[{ pointer: form.coverImage != null }]"
                >
                  <img
                    src="@/assets/images/icons/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="isLoading"
                  />
                </div>
              </b-col>
              <b-col>
                <UploadFile
                  textFloat="Cover Image"
                  placeholder="Please choose file"
                  format="image"
                  name="thumbnail"
                  text="*Please upload only file .png, .jpg only with 1:1 image less than 1 MB"
                  isRequired
                  v-on:onFileChange="onImageChange"
                  v-on:delete="deleteImage"
                  v-model="form.coverImage"
                  :fileName="form.coverImage"
                  :v="$v.form.coverImage"
                  :isValidate="$v.form.coverImage.$error"
                  :checkRegRatio="false"
                />
              </b-col>
            </b-row>

            <div class="mb-3">
              <div class="position-relative">
                <b-button
                  v-b-toggle.collapse-1
                  variant="primary"
                  class="seo-btn"
                  >ชำระผ่านเคาน์เตอร์เซอร์วิสที่ร้านสะดวกซื้อ</b-button
                >
                <font-awesome-icon icon="sort-down" class="arrow-logo r-15" />
              </div>
              <b-collapse visible id="collapse-1" class="mt-2">
                <b-card>
                  <InputTextArea
                    placeholder="Enter something..."
                    type="text"
                    name="counterservice"
                    v-model="form.payment7Eleven"
                    :isValidate="$v.form.payment7Eleven.$error"
                    :v="$v.form.payment7Eleven"
                    :noHeader="true"
                    rows="3"
                  />
                </b-card>
              </b-collapse>
            </div>

            <div class="mb-3">
              <div class="position-relative">
                <b-button
                  v-b-toggle.collapse-2
                  variant="primary"
                  class="seo-btn"
                  >ชำระผ่านเคาน์เตอร์ โลตัสและบิ๊กซี</b-button
                >
                <font-awesome-icon icon="sort-down" class="arrow-logo r-15" />
              </div>
              <b-collapse visible id="collapse-2" class="mt-2">
                <b-card>
                  <InputTextArea
                    placeholder="Enter something..."
                    type="text"
                    name="counterservicebigc"
                    v-model="form.paymentTescoLotus"
                    :isValidate="$v.form.paymentTescoLotus.$error"
                    :v="$v.form.paymentTescoLotus"
                    :noHeader="true"
                    rows="3"
                  />
                </b-card>
              </b-collapse>
            </div>

            <div class="mb-3">
              <div class="position-relative">
                <b-button
                  v-b-toggle.collapse-3
                  variant="primary"
                  class="seo-btn"
                  >ชำระผ่านแอปพลิเคชั่นธนาคาร</b-button
                >
                <font-awesome-icon icon="sort-down" class="arrow-logo r-15" />
              </div>
              <b-collapse visible id="collapse-3" class="mt-2">
                <b-card>
                  <InputTextArea
                    placeholder="Enter something..."
                    type="text"
                    name="counterservicebigc"
                    v-model="form.paymentBankApp.text"
                    :isValidate="$v.form.paymentBankApp.text.$error"
                    :v="$v.form.paymentBankApp.text"
                    :noHeader="true"
                    rows="3"
                  />

                  <div class="my-5">
                    <b-table
                      responsive
                      class="text-center"
                      striped
                      :fields="bankAppField"
                      :items="form.paymentBankApp.bankList"
                      :busy="isBusy"
                      show-empty
                      empty-text="No matching records found"
                    >
                      <template v-slot:cell(imageUrl)="data">
                        <div class="d-flex justify-content-center">
                          <div
                            class="image-bank-logo"
                            v-bind:style="{
                              'background-image':
                                'url(' + data.item.imageUrl + ')',
                            }"
                          ></div>
                        </div>
                      </template>
                      <template v-slot:cell(linkUrl)="data">
                        <b-form-input
                          v-model="data.item.linkUrl"
                          :disabled="!data.item.status"
                          :class="[
                            'url-link-input',
                            {
                              error:
                                $v.form.paymentBankApp.bankList.$each.$iter[
                                  data.index
                                ].linkUrl.$error,
                            },
                          ]"
                        />
                      </template>
                      <template v-slot:cell(sortOrder)="data">
                        <b-form-input
                          v-model="data.item.sortOrder"
                          :disabled="!data.item.status"
                          :class="[
                            'inline-input',
                            {
                              error:
                                $v.form.paymentBankApp.bankList.$each.$iter[
                                  data.index
                                ].sortOrder.$error,
                            },
                          ]"
                          @keypress="isNumber($event)"
                        />
                      </template>
                      <template v-slot:cell(status)="data">
                        <b-form-checkbox
                          switch
                          v-model="data.item.status"
                          class="radio-active"
                          size="lg"
                        ></b-form-checkbox>
                      </template>
                    </b-table>
                  </div>

                  <span
                    class="text-danger mt-3"
                    v-if="$v.form.paymentBankApp.bankList.$each.$error"
                    >Please enter the correct data</span
                  >
                </b-card>
              </b-collapse>
            </div>

            <div class="mb-3">
              <div class="position-relative">
                <b-button
                  v-b-toggle.collapse-4
                  variant="primary"
                  class="seo-btn"
                  >ชำระผ่านบริการ SKL Connect</b-button
                >
                <font-awesome-icon icon="sort-down" class="arrow-logo r-15" />
              </div>
              <b-collapse visible id="collapse-4" class="mt-2">
                <b-card>
                  <InputTextArea
                    placeholder="Enter something..."
                    type="text"
                    name="sklconnect"
                    v-model="form.paymentSKLConnect.text"
                    :isValidate="$v.form.paymentSKLConnect.text.$error"
                    :v="$v.form.paymentSKLConnect.text"
                    :noHeader="true"
                    rows="3"
                  />

                  <div class="my-5">
                    <b-table
                      responsive
                      class="text-center"
                      striped
                      :fields="sklConnectField"
                      :items="form.paymentSKLConnect.bankList"
                      :busy="isBusy"
                      show-empty
                      empty-text="No matching records found"
                    >
                      <template v-slot:cell(imageUrl)="data">
                        <div class="d-flex justify-content-center">
                          <div
                            class="image-bank-logo"
                            v-bind:style="{
                              'background-image':
                                'url(' + data.item.imageUrl + ')',
                            }"
                          ></div>
                        </div>
                      </template>
                      <template v-slot:cell(code)="data">
                        <b-form-input
                          v-model="data.item.code"
                          :disabled="!data.item.status"
                          :class="[
                            'inline-input',
                            {
                              error:
                                $v.form.paymentSKLConnect.bankList.$each.$iter[
                                  data.index
                                ].code.$error,
                            },
                          ]"
                        />
                      </template>
                      <template v-slot:cell(linkUrl)="data">
                        <b-form-input
                          v-model="data.item.linkUrl"
                          :disabled="!data.item.status"
                          :class="[
                            'url-link-input',
                            {
                              error:
                                $v.form.paymentSKLConnect.bankList.$each.$iter[
                                  data.index
                                ].linkUrl.$error,
                            },
                          ]"
                        />
                      </template>
                      <template v-slot:cell(sortOrder)="data">
                        <b-form-input
                          v-model="data.item.sortOrder"
                          :disabled="!data.item.status"
                          :class="[
                            'inline-input',
                            {
                              error:
                                $v.form.paymentSKLConnect.bankList.$each.$iter[
                                  data.index
                                ].sortOrder.$error,
                            },
                          ]"
                          @keypress="isNumber($event)"
                        />
                      </template>
                      <template v-slot:cell(status)="data">
                        <b-form-checkbox
                          switch
                          v-model="data.item.status"
                          class="radio-active"
                          size="lg"
                        ></b-form-checkbox>
                      </template>
                    </b-table>
                  </div>

                  <span
                    class="text-danger mt-3"
                    v-if="$v.form.paymentSKLConnect.bankList.$each.$error"
                    >Please enter the correct data</span
                  >
                </b-card>
              </b-collapse>
            </div>

            <div class="mb-3">
              <div class="position-relative">
                <b-button
                  v-b-toggle.collapse-6
                  variant="primary"
                  class="seo-btn"
                  >ชำระผ่านเคาน์เตอร์ธนาคาร</b-button
                >
                <font-awesome-icon icon="sort-down" class="arrow-logo r-15" />
              </div>
              <b-collapse visible id="collapse-6" class="mt-2">
                <b-card>
                  <InputTextArea
                    placeholder="Enter something..."
                    type="text"
                    name="counterbank"
                    v-model="form.paymentBankCounter.text"
                    :isValidate="$v.form.paymentBankCounter.text.$error"
                    :v="$v.form.paymentBankCounter.text"
                    :noHeader="true"
                    rows="3"
                  />

                  <div>
                    <b-table
                      responsive
                      class="text-center"
                      striped
                      :fields="bankCounterField"
                      :items="form.paymentBankCounter.bankList"
                      :busy="isBusy"
                      show-empty
                      empty-text="No matching records found"
                    >
                      <template v-slot:cell(imageUrl)="data">
                        <div class="d-flex justify-content-center">
                          <div
                            class="image-bank-logo"
                            v-bind:style="{
                              'background-image':
                                'url(' + data.item.imageUrl + ')',
                            }"
                          ></div>
                        </div>
                      </template>
                      <template v-slot:cell(sortOrder)="data">
                        <b-form-input
                          v-model="data.item.sortOrder"
                          :disabled="!data.item.status"
                          :class="[
                            'inline-input',
                            {
                              error:
                                $v.form.paymentBankCounter.bankList.$each.$iter[
                                  data.index
                                ].sortOrder.$error,
                            },
                          ]"
                          @keypress="isNumber($event)"
                        />
                      </template>
                      <template v-slot:cell(status)="data">
                        <b-form-checkbox
                          switch
                          v-model="data.item.status"
                          class="radio-active"
                          size="lg"
                        ></b-form-checkbox>
                      </template>
                    </b-table>
                  </div>

                  <span
                    class="text-danger mt-3"
                    v-if="$v.form.paymentBankCounter.bankList.$each.$error"
                    >Please enter the correct data</span
                  >
                </b-card>
              </b-collapse>
            </div>

            <!-- Button Cancel  Exit -->
            <b-row class="mt-5">
              <b-col md="6"></b-col>
              <b-col md="6" class="text-sm-right">
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(0)"
                  :disabled="isDisable"
                >
                  Save
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </form>

    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="isModalAlertConfirm"
      @confirm="btnDelete"
    />

    <b-modal
      id="showPreviewModal"
      ref="showPreviewModal"
      hide-header
      hide-footer
      no-close-on-backdrop
      centered
      body-class="p-4"
      size="lg"
    >
      <div class="modal-header border-0 px-0 pt-0">
        <h3 class="font-weight-bold">Image Preview</h3>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('showPreviewModal')"
        >
          ×
        </button>
      </div>
      <div>
        <b-container class="p-0">
          <b-row>
            <b-col>
              <div
                class="preview-box b-contain m-auto border-0"
                v-bind:style="{ 'background-image': 'url(' + img + ')' }"
              ></div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";
import UploadFile from "@/components/inputs/UploadFile";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import { required, minValue, requiredIf, url } from "vuelidate/lib/validators";

export default {
  name: "PaymentChannel",
  components: {
    InputTextArea,
    UploadFile,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
  },
  data() {
    return {
      img: "",
      isDisable: false,
      isLoading: false,
      isBusy: false,
      modalMessage: "",
      items: [],
      form: null,
      bankAppField: [
        { key: "imageUrl", label: "#" },
        { key: "name", label: "Bank name", class: "w-100px text-nowrap" },
        { key: "linkUrl", label: "URL Link", class: "w-100px text-nowrap" },
        { key: "sortOrder", label: "Sort Order", class: "w-100px text-nowrap" },
        { key: "status", label: "Status", class: "w-100px text-nowrap" },
      ],
      sklConnectField: [
        { key: "imageUrl", label: "#" },
        { key: "name", label: "Bank name", class: "w-100px text-nowrap" },
        { key: "code", label: "Code", class: "w-100px text-nowrap" },
        { key: "linkUrl", label: "URL Link", class: "w-100px text-nowrap" },
        { key: "sortOrder", label: "Sort Order", class: "w-100px text-nowrap" },
        { key: "status", label: "Status", class: "w-100px text-nowrap" },
      ],
      bankCounterField: [
        { key: "imageUrl", label: "#" },
        { key: "name", label: "Bank name", class: "w-100px text-nowrap" },
        { key: "sortOrder", label: "Sort Order", class: "w-100px text-nowrap" },
        { key: "status", label: "Action", class: "w-100px text-nowrap" },
      ],
    };
  },
  validations: {
    form: {
      coverImage: { required },
      payment7Eleven: { required },
      paymentTescoLotus: { required },
      paymentBankApp: {
        text: { required },
        bankList: {
          $each: {
            linkUrl: {
              required: requiredIf(function (value) {
                return value.status == true;
              }),
              url,
            },
            sortOrder: { required },
          },
        },
      },
      paymentSKLConnect: {
        text: { required },
        bankList: {
          $each: {
            linkUrl: {
              required: requiredIf(function (value) {
                return value.status == true;
              }),
              url,
            },
            sortOrder: { required },
            code: {
              required: requiredIf(function (value) {
                return value.status == true;
              }),
            },
          },
        },
      },
      paymentBankCounter: {
        text: { required },
        bankList: {
          $each: {
            sortOrder: { required },
          },
        },
      },
    },
  },
  created: async function () {
    this.$isLoading = true;
    await this.getData();
    this.$isLoading = false;
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getData: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/paymentChannel`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.form = resData.detail;
      }
    },
    onImageChange(img) {
      this.isLoading = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoading = false;
        this.isDisable = false;
        this.form.coverImage = this.images;
      };
    },
    deleteImage(img) {
      this.form.coverImage = null;
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        resize: true,
        base64: img,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    saveForm: async function (flag) {
      if (this.form.coverImage == null) {
        this.modalMessage = "Please upload a cover image.";
        this.$refs.modalAlertError.show();
        return;
      }

      this.$v.form.$touch();

      for (var i = 0; i < this.form.paymentBankApp.bankList.length; i++) {
        if (
          this.$v.form.paymentBankApp.bankList.$each.$iter[i].linkUrl.$error
        ) {
          this.modalMessage = "Please enter text in URL format";
          this.$refs.modalAlertError.show();
          return;
        }
      }

      for (var i = 0; i < this.form.paymentSKLConnect.bankList.length; i++) {
        if (
          this.$v.form.paymentSKLConnect.bankList.$each.$iter[i].linkUrl.$error
        ) {
          this.modalMessage = "Please enter text in URL format";
          this.$refs.modalAlertError.show();
          return;
        }
      }

      if (this.$v.form.$error) return;

      this.isDisable = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/paymentChannel/save`,
        null,
        this.$headers,
        this.form
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        this.$isLoading = true;
        this.getData();
        this.$isLoading = false;
      } else {
        this.$refs.modalAlertError.show();
      }
      this.isDisable = false;
    },
    btnDelete: async function () {},
  },
};
</script>